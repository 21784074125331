import React from "react"
import { RichText } from 'prismic-reactjs'
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Project from "../components/projet-solidaire"
import Image from "../components/image"
import SEO from "../components/seo"

export const query = graphql`
{
  prismic {
    allMaison_ouverte_aux_etudiantss {
      edges {
        node {
          titre_1
          image_de_fonds1
          image_de_fonds1Sharp {
            childImageSharp {
              fluid(maxWidth: 3850) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          introduction
          description_1
          titre_2
          contenu_2 {
            image
            imageSharp {
              childImageSharp {
                fixed(width: 150) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            page_address
            page_description
          }
          titre_3
          description_3
        }
      }
    }
  }
}
`
const IndexPage = ({ data }) => {
  const doc = data.prismic.allMaison_ouverte_aux_etudiantss.edges.slice(0,1).pop().node;
  return (
  <Layout>
    <SEO title={RichText.asText(doc.description_1)} />
    <Image title={RichText.asText(doc.titre_1)} 
          text={RichText.render(doc.description_1)} 
          img={doc.image_de_fonds1Sharp.childImageSharp.fluid} />
    <section class="content-center main-content">
      {RichText.render(doc.introduction)}
      {RichText.render(doc.titre_2)}
      <div class="grid-2">
      {doc.contenu_2.map(section => (
        <Link to={section.page_address} class="section-projet">
          <div class="flex-container">
            <div class="item-center">
              <Img fixed={section.imageSharp.childImageSharp.fixed} />
            </div>
     
          </div>
          <h3 class="txtcenter">{section.page_description}</h3>
          </Link>     
        ))
      }
      </div>
    
    </section>
    <Project />
  </Layout>
  )
}

export default IndexPage
