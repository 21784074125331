import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { RichText } from 'prismic-reactjs'
import Img from "gatsby-image"

const Project = ({data}) => {

  const doc = data.prismic.allProjet_solidaires.edges.slice(0,1).pop().node;

  return (
     <section class="content-center main-content">
      {RichText.render(doc.titre_principal)}
      {RichText.render(doc.contenu_1)}
      <div class="grid-3">
      {doc.aides.map(section => (
          <section id="solidaire" class="section-projet">
            <div class="flex-container">
              <div class="item-center">
                <Img fixed={section.imageSharp.childImageSharp.fixed} />
              </div>
            </div>
            {RichText.render(section.titre)}
            {RichText.render(section.description)}
          </section>
        ))
      }
      </div>
      {RichText.render(doc.contenu_2)}
  </section>
)
}

export default props => (
  <StaticQuery
    query={graphql`
    query MyQuery {
      prismic {
        allProjet_solidaires {
          edges {
            node {
              titre_principal
              contenu_2
              contenu_1
              aides {
                description
                image
                imageSharp {
                  childImageSharp {
                    fixed(width: 150) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                titre
              }
            }
          }
        }
      }
    }
    `}
    render={data => <Project data={data} {...props} />}
  />
)
